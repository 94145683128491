import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-click--collect-contactless-pickup"
    }}>{`Configure Click & Collect (Contactless Pickup)`}</h1>
    <hr></hr>
    <p>{`To enable Click & Collect for your mobile app, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Channels section from the left side menu options. Click on the Channel where you want to add Click & Collect. `}</li>
      <li parentName="ol">{`Within the selected Channel, select the 'Collection / Pickup' tab`}</li>
      <li parentName="ol">{`Toggle the 'Click & Collect enabled' option to enable the feature for this specific Channel.`}</li>
      <li parentName="ol">{`Provide the hours of operation for pickup requests by entering them in as 24-hour-clock values. If a patron attempts to request pickup during a time the service is not available, a message will appear in the app informing the patron that the service is currently closed. `}</li>
      <li parentName="ol">{`In the 'Queue Length' field, enter in the maximum number of patrons that can be in the system at any given time. When the queue length is full, a message will appear in the app informing the patron that the library cannot process their request at this time. `}</li>
      <li parentName="ol">{`Provide the SIP2 Username, Password, Location and Port connection information if you want all checkouts to be processed this way at the Channel level. If left blank, the checkout requests will be processed through the SIP2 connection information at the app level. `}</li>
    </ol>
    <p><img alt="CMS Collection / Pickup Tab" src={require("./images/collection_pickup_tab.png")} />{` `}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`The app utilizes multiple screens to guide patrons through the process of remote item pickup. Each screen consists of various fields and buttons that prompt users for information or provide guidance. The labels on these screens can be customized to align with your library's specific pickup process. `}</li>
    </ol>
    <p><img alt="CMS Screens" src={require("./images/cc_screens.png")} />{` `}</p>
    <p>{`Prepare: The fields used to help initialize the pickup request process. `}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Title specifies the name of the page.`}</li>
      <li parentName="ul">{`Subtitle is a longer description of the page. The app will dynamically replace the {0} variable with the actual number of items the patron has on hold. The {1} variable will be replaced with the closing time of Click & Collect for that day.`}</li>
      <li parentName="ul">{`Item Display will details how the items being checked out are displayed in your app. `}
        <ul parentName="li">
          <li parentName="ul">{`None will show no items in the app. `}</li>
          <li parentName="ul">{`Carousel will display items in a rotating display.`}</li>
          <li parentName="ul">{`List displays a list of the items. `}</li>
          <li parentName="ul">{`List with Selection displays a list of the items with checkboxes so the patron can select which items they would like to pickup. `}</li>
        </ul>
      </li>
      <li parentName="ul">{`Questions Title is the text that precedes the identifying questions.`}</li>
      <li parentName="ul">{`Question (1-4) are the questions that help identify the patron or the patron's vehicle. Questions left blank will not appear in your app. `}</li>
      <li parentName="ul">{`Question Hint (1-4) is the text that appears in the field before the patron enters in a response. `}</li>
      <li parentName="ul">{`Footer details the text that appears after the questions.`}</li>
      <li parentName="ul">{`Submit Button Label specifies the button text for submitting the pickup request. `}</li>
      <li parentName="ul">{`Cancel Button Label specifies the button text for when a patron cancels the pickup request. `}</li>
      <li parentName="ul">{`Cancel Confirmation specifies the text that displays when a patron cancels their pickup request. `}</li>
    </ul>
    <p>{`En Route: The fields that help prompt and guide the patron when they arrive at the location for pickup. `}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Title specifices the name of the page. `}</li>
      <li parentName="ul">{`Subtitle is a longer description of the page. `}</li>
      <li parentName="ul">{`Late Warning allows you to provide notifications to patrons when their estimated time of arrival exceeds the Late Warning Buffer. One variable is available for use in the Late Warning text: {0} - This variable represents the time when Click & Collect closes, based on the closing time indicated in Active Hours for the current day. `}</li>
      <li parentName="ul">{`Late Warning Buffer (mins) is the amount of time before warning patrons of when the pickup services will close. This warning will display when one of these conditions has been met: `}
        <ul parentName="li">
          <li parentName="ul">{`The patron has not yet indicated their ETA. `}</li>
          <li parentName="ul">{`The patron's manually entered ETA is greater than the Late Warning Buffer. `}</li>
          <li parentName="ul">{`The automatic ETA is greater than the Late Warning Buffer. `}</li>
        </ul>
      </li>
      <li parentName="ul">{`Show Auto ETA will detail whether or not to show the Auto ETA button within your app. `}</li>
      <li parentName="ul">{`Auto ETA Description is the text that details what Auto ETA does. This will only display in your app if 'Show Auto ETA' is enabled. `}</li>
      <li parentName="ul">{`Auto ETA Button Label is the button text that activates the Auto ETA. This will only display in the app if 'Show Auto ETA' is enabled. `}</li>
      <li parentName="ul">{`Show Manual ETA details whether or not to show the Manual ETA button within the app. `}</li>
      <li parentName="ul">{`Manual ETA Description is the text that details what Manual ETA does. This will only display in the app if "Show Manual ETA' is enabled. `}</li>
      <li parentName="ul">{`Manual ETA Options details the numeric values options for Manual ETA. These values must be numeric and separated by commas. A button is added for each value listed. This will only display in the app if 'Show Manual ETA' is enabled.  `}</li>
      <li parentName="ul">{`Footer specifies he text that appears after the ETA options. `}</li>
      <li parentName="ul">{`Submit Button Label is the button text for submitting the pickup request. `}</li>
      <li parentName="ul">{`Cancel Button Label is the button text for cancelling the pickup request. `}</li>
      <li parentName="ul">{`Cancel Confirmation is the text that appears when a patron cancels their pickup request.`}</li>
    </ul>
    <p>{`Arrived: The fields used to inform patrons of the status of their items after they have arrived. `}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Title is the name of the page. `}</li>
      <li parentName="ul">{`Subtitle details the first message sent to the patron. `}</li>
      <li parentName="ul">{`Status Title details the tital of the message box where patrons can send and receive status updates. `}</li>
      <li parentName="ul">{`Footer is the test that apperas after the status messages. `}</li>
      <li parentName="ul">{`Allow Patrons to Complete Session will allow the patron to select "Done" within your app. This allows them to complete the pickup request. `}</li>
      <li parentName="ul">{`Submit Button Label is the button text for submitting the pickup request. `}</li>
      <li parentName="ul">{`Cancel Button Label is the button text for cancelling the pickup request. `}</li>
      <li parentName="ul">{`Cancel Confirmation is the text that appears when a patron cancels their pickup request. `}</li>
    </ul>
    <p>{`Thanks: The fields used to inform the patron of when their pickup request is completed.`}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Title is the name of the page. `}</li>
      <li parentName="ul">{`Subtitle is a longer description of the page. `}</li>
      <li parentName="ul">{`Footer is the text detailed at the bottom of the page. `}</li>
      <li parentName="ul">{`Close Button Label details the text to close out of the request pickup. `}</li>
    </ul>
    <p>{`Cancelled: The fields used to inform the patron that the pickup process has been cancelled. `}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Title is the name of the page. `}</li>
      <li parentName="ul">{`Subtitle is a longer description of the page. `}</li>
      <li parentName="ul">{`Footer is the text detailed at the bottom of the page. `}</li>
      <li parentName="ul">{`Close Button Label details the text to close out of the request pickup. `}</li>
    </ul>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Exception Screens inform the patron why the pickup request is currently unavailable. `}</li>
    </ol>
    <p><img alt="CMS Exception Screens" src={require("./images/cc_exception_screens.png")} />{` `}</p>
    <p>{`Outside of Hours: The fields used to inform the patron that the request for pickup is outside of the hours set in the Active Hours section above. `}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Title is the name of the page. `}</li>
      <li parentName="ul">{`Subtitle is a longer description of the page. `}</li>
      <li parentName="ul">{`Hours Header is the title box that details the hours when pickup is available at the location. `}</li>
      <li parentName="ul">{`Show Phone will display the library's phone number. This will always be the phone number listed under the Mobile Channel Settings. `}</li>
      <li parentName="ul">{`Phone Message is the text that details what the call button is for. Only displays when the 'Show Phone' is enabled. `}</li>
    </ul>
    <p>{`Unavailable: The fields used to inform the patron that pickup is not available right now. An Unavailable screen will open when the Staff Dashboard is in the Inactive state. `}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Title is the name of the page. `}</li>
      <li parentName="ul">{`Subtitle is a longer description of the page. `}</li>
      <li parentName="ul">{`Refresh Button Label is the button text for retrying to submit the pickup request. `}</li>
      <li parentName="ul">{`Show Phone will display the library's phone number. This will always be the phone number listed under the Mobile Channel Settings. `}</li>
      <li parentName="ul">{`Phone Message is the text that details what the call button is for. Only displays when the 'Show Phone' is enabled. `}</li>
    </ul>
    <p>{`Busy: The fields used to inform that patron the pickup queue is full. `}</p>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Title is the name of the page. `}</li>
      <li parentName="ul">{`Subtitle is a longer description of the page. `}</li>
      <li parentName="ul">{`Refresh Button Label is the button text for retrying to submit the pickup request. `}</li>
      <li parentName="ul">{`Show Phone will display the library's phone number. This will always be the phone number listed under the Mobile Channel Settings. `}</li>
      <li parentName="ul">{`Phone Message is the text that details what the call button is for. Only displays when the 'Show Phone' is enabled. `}</li>
      <li parentName="ul">{`Show Queue will display the queue message within your app. `}</li>
      <li parentName="ul">{`Queue Message is the text that informs the patron where they are at in the queue. Use "{0}" as the placeholder for the patron's location in the queue and "{1}" as the placeholder for the total number of patrons in the queue. `}</li>
    </ul>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Enter any pre-written messages to be used in the Staff Dashboard in the 'Status Responses' field. These will be message options, within the Staff Dashboard, that you can select from to send to your patrons. `}</li>
      <li parentName="ol">{`To restrict access to the Click & Collect function during testing, list the barcodes of the users in the 'Allowed Users' field, separated by commas. Entering barcodes here will limit user access at the channel level. After testing, you can remove the usernames to grant access to all users and patrons.`}</li>
      <li parentName="ol">{`Enable 'Allow Checkout from Ready queue' if you want to allow staff members to checkout items to the patron while the pickup request is in the Ready column on the Staff Dashboard. `}</li>
    </ol>
    <p><img alt="CMS Status Responses" src={require("./images/cc_status_responses.png")} />{` `}</p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Once you've made the necessary adjustments, click 'Save' at the bottom of the page to save the changes.`}</li>
      <li parentName="ol">{`Navigate to the 'Mobile Apps' section from the left side menu options and click on the mobile app for which you want to enable Click & Collect.`}</li>
      <li parentName="ol">{`Click on 'Edit' next to the Data Provider.`}</li>
      <li parentName="ol">{`In the Click & Collect section, toggle 'Collection Enabled' to enable Click & Collect for this mobile app.`}</li>
      <li parentName="ol">{`To limit users that can access Click & Collect at the app level, list the user barcodes in the 'Allowed Users' field separated by commas. After testing, you can remove the usernames to grant access to all patrons. `}</li>
      <li parentName="ol">{`Provide the SIP2 Username, Password, Location and Port connection information if you want all checkouts to be processed this way at the app level. If the Channel level SIP2 information was left blank, all checkout requests will be processed through this connection. `}</li>
    </ol>
    <p><img alt="CMS CC Edit DP" src={require("./images/cc_edit_dp.png")} />{` `}</p>
    <ol {...{
      "start": 18
    }}>
      <li parentName="ol">{`Once you've completed all the configurations, click 'Save' to save the changes. `}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      